import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { getTours, getTourGroups } from "../Api/TourApi";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Col,
  Row,
} from "reactstrap";
import { Image, Transformation } from "cloudinary-react";
import Banner from "react-js-banner";

const bannerStyle = {
  backgroundColor: "black",
  width: "100%",
  position: "fixed",
  top: "90px",
  zIndex: "900",
  textAlign: "center",
  // paddingBottom: 5, Holiday banner
  padding: 5,
};

const imageStyle = {
  height: "50px", // Set your desired height
  // marginLeft: "10px", // Optional spacing from the left edge
};

const TopNav = (props) => {
  const [isPhoneScreen, setIsPhoneScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function handleResize() {
      setIsPhoneScreen(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const phoneOnlyStyle = {
    display: isPhoneScreen ? "initial" : "none",
  };

  // Hook  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [origTours, setOrigTours] = useState([]);

  useEffect(() => {
    let cancelled = false;
    async function fetchTours() {
      const response = await getTours();
      if (!cancelled) {
        setOrigTours(
          response.data.sort(
            (a, b) => a["attributes"].sortOrder - b["attributes"].sortOrder
          )
        );
      }
    }
    fetchTours();

    return () => {
      cancelled = true;
    };
  }, []);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  let style;
  if (windowSize.width) {
    if (windowSize.width > 1500) {
      style = { width: "1450px", left: "-450px" };
    } else if (windowSize.width > 1400) {
      style = { width: "1350px", left: "-380px" };
    } else if (windowSize.width > 1300) {
      style = { width: "1200px", left: "-280px" };
    } else if (windowSize.width > 991) {
      style = {
        width: Math.ceil(windowSize.width - windowSize.width / 10) + "px",
        left: Math.ceil((windowSize.width / 5) * -1) + "px",
        marginRight: "10px",
      };
    }
  }

  let bannerCss = {
    b1C: { color: "#FFF", backgroundColor: "green", width: "93%" },
  };

  return (
    <>
      <Navbar
        color="light"
        light
        fixed="top"
        expand="lg"
        style={{ zIndex: "1000" }}
      >
        {/* <Banner css={bannerCss.b1C}>
        <div>
          <a href="gift-certificates" style={{ color: "white" }}>
            20% off Gift Certificates
            <span style={{ fontWeight: 100 }}>
              <br />
              (Limited Time Only)
            </span>
          </a>
        </div>
      </Banner>
      <br /> */}
        <Container>
          <NavbarBrand href="/">
            <Image
              cloudName="american-legacy-tours"
              publicId="/img/logo-color-big"
              fetchpriority="high"
              fetch_format="auto"
              width={187}
              height={66}
              style={{ width: 187, height: 66 }}
            >
              <Transformation height="66" width="187" />
            </Image>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="m-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  caret
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    color: "#000000",
                  }}
                >
                  Tours
                </DropdownToggle>
                <DropdownMenu style={style}>
                  <Row style={{ marginLeft: "10px" }}>
                    <Col sm="12" md="3">
                      <DropdownItem
                        header
                        style={{
                          fontWeight: "bold",
                          fontSize: "120%",
                          padding: "0.25rem",
                        }}
                      >
                        Underground Tours
                      </DropdownItem>
                      <>
                        {origTours.map((tour) =>
                          tour["attributes"].groups.includes("underground") &&
                          tour["attributes"].enabled ? (
                            <DropdownItem
                              href={tour["attributes"].pathTitle}
                              style={{ padding: "0.25rem" }}
                              className="text-wrap"
                            >
                              {tour["attributes"].title}
                            </DropdownItem>
                          ) : null
                        )}
                      </>
                    </Col>
                    <Col sm="12" md="3">
                      <hr className="d-block d-md-none smallonly" />
                      <DropdownItem
                        header
                        style={{
                          fontWeight: "bold",
                          fontSize: "120%",
                          padding: "0.25rem",
                        }}
                        className="text-wrap"
                      >
                        Historic Tours
                      </DropdownItem>
                      <>
                        {origTours.map((tour) =>
                          tour["attributes"].groups.includes("historic") &&
                          tour["attributes"].enabled ? (
                            <DropdownItem
                              href={tour["attributes"].pathTitle}
                              style={{ padding: "0.25rem" }}
                              className="text-wrap"
                            >
                              {tour["attributes"].title}
                            </DropdownItem>
                          ) : null
                        )}
                      </>
                    </Col>
                    <Col sm="12" md="3">
                      <hr className="d-block d-md-none smallonly" />
                      <DropdownItem
                        header
                        style={{
                          fontWeight: "bold",
                          fontSize: "120%",
                          padding: "0.25rem",
                        }}
                      >
                        Food &amp; Drink Tours
                      </DropdownItem>
                      <>
                        {origTours.map((tour) =>
                          tour["attributes"].groups.includes("foodanddrink") &&
                          tour["attributes"].enabled ? (
                            <DropdownItem
                              href={tour["attributes"].pathTitle}
                              style={{ padding: "0.25rem" }}
                              className="text-wrap"
                            >
                              {tour["attributes"].title}
                            </DropdownItem>
                          ) : null
                        )}
                      </>
                    </Col>
                    <Col sm="12" md="3">
                      <hr className="d-block d-md-none smallonly" />
                      <DropdownItem
                        header
                        style={{
                          fontWeight: "bold",
                          fontSize: "120%",
                          padding: "0.25rem",
                        }}
                      >
                        Haunted Tours
                      </DropdownItem>
                      <>
                        {origTours.map((tour) =>
                          tour["attributes"].groups.includes("haunted") &&
                          tour["attributes"].enabled ? (
                            <DropdownItem
                              href={tour["attributes"].pathTitle}
                              style={{ padding: "0.25rem" }}
                              className="text-wrap"
                            >
                              {tour["attributes"].title}
                            </DropdownItem>
                          ) : null
                        )}
                      </>
                    </Col>
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <NavLink href="/gift-certificates" style={{ color: "#000000" }}>
                  Gift&nbsp;Cards
                </NavLink>
              </NavItem>
              <NavItem
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <NavLink href="/event-calendar" style={{ color: "#000000" }}>
                  Calendar
                </NavLink>
              </NavItem>
              <NavItem
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <NavLink href="/private-tours" style={{ color: "#000000" }}>
                  Private&nbsp;Tours
                </NavLink>
              </NavItem>
              <NavItem
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <NavLink href="/faq" style={{ color: "#000000" }}>
                  FAQ
                </NavLink>
              </NavItem>
              <NavItem
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <NavLink href="/contact-us" style={{ color: "#000000" }}>
                  Contact&nbsp;Us
                </NavLink>
              </NavItem>
              <UncontrolledDropdown
                nav
                inNavbar
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  color: "#000000",
                }}
              >
                <DropdownToggle nav caret style={{ color: "#000000" }}>
                  About&nbsp;Us
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href="/about-us">Our Story</DropdownItem>
                  <DropdownItem href="/featured">Featured In</DropdownItem>
                  <DropdownItem href="/friends">Our Friends</DropdownItem>
                  <DropdownItem href="/hiring">Now Hiring</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {/* <div style={bannerStyle}>
      <a
          href="/gift-certificates"
          style={{ color: "white", paddingLeft: 20 }}
        >
      <img
          src="/20offgc-holiday.png"
          alt="20% off Gift Cards Banner"
          style={imageStyle}
        /> */}
        {/* <br style={phoneOnlyStyle} /> */}
          {/* <br /> */}
          {/* <span style={{ fontWeight: "bold" }}>20% OFF&nbsp;</span>
          GIFT CERTIFICATES
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
          {/* <br style={phoneOnlyStyle} /> */}
          {/* Now through Nov 27th */}
      {/* </a>
      </div> */}
    </>
  );
};
export default TopNav;
